/* /home.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
}

header {
    width: auto;
    margin: 0 auto;
    overflow-x: hidden;
}

#top {
    margin-bottom: 35px;
    font-size: 45px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /* Shadow effect */
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#go-to-portal{
    color: white;
    text-decoration: none;
    width: 200px;
    height: 100px;
    color: white;
    background: #5F0197;
    border: 1px solid #5F0197;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15);
    font-weight: bold;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
}

#top::after {
    content: "";
    position: absolute;
    left: -50%;
    right: -50%;
    bottom: -10px;
    border-bottom: 10px solid purple;
}

#line {
    width: 500px;
    background-color: purple;
    border-bottom: 1px solid purple;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 35%;
    height: 10px;
    margin: 0 auto;
}

#greeting {
    text-align: center;
    color: black;
    font-size: 30px;
    margin-top: 30px;
}

#homeLogo {
    width: 175px;
    height: 190px;
    margin-top: 55px;
    margin-left: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


#texts {
    width: 1200px;
    margin: 0 auto;
}

#services {
    margin-top: 15px;
    width: 1200px;
    margin: 0 auto;
}

ul {
    display: flex;
    width: 1050px;
    height: 247px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 10px;
}


li {
    width: 450px;
    margin-top: 35px;
    margin-left: 15%;
    list-style-type: square;
    font-weight: normal;

}

#GenerealPhoto {
    width: auto;
    background-image: url(https://truckinginsurance-tx.com/wp-content/uploads/2023/12/Commercial-Truck-Insurance-Texas-1536x698.jpg);
    height: 450px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 35px;
    color: white;


}

#demonstration {
    margin-top: 50px;
    text-align: center;

}

#payment {
    text-align: center;
    margin-top: 50px;
}

#btns {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    flex-direction: column;
    gap: 50px;
    margin-top: 50px;

}

.BtnLinks {
    color: white;
    text-decoration: none;
    width: 200px;
    height: 100px;
    color: white;
    background: #5F0197;
    border: 1px solid #5F0197;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15);
    font-weight: bold;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
}

#footer {
    width: 100vw;
    height: 50vh;
    border: solid 1px #5F0197;
    color: white;
    background: #5F0197;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
}

#Email {
    margin-top: 50px;
}

#mailto {
    font-weight: normal;
    color: white;
    text-decoration: none;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
}

#go-to-contact {
    color: white;
    text-decoration: none;
    width: 200px;
    height: 100px;
    color: white;
    background: #5F0197;
    border: 1px solid #5F0197;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15);
    font-weight: bold;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
}

#footermail {
    text-decoration: none;
    color: white;
}

/* Base styles */
/* ... existing styles ... */

/* Media queries */

/* Phones (portrait and landscape) */
@media only screen and (max-width: 600px) {
    #GenerealPhoto {
        width: auto;
        height: 450px;
        background-size: cover cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(https://truckinginsurance-tx.com/wp-content/uploads/2023/12/Commercial-Truck-Insurance-Texas-1536x698.jpg);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 25px;
        text-decoration: underline purple;
        object-fit: cover;
        object-position: center;
        color: white;

    }

    #top {
        margin-bottom: 35px;
        font-size: 24px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        /* Shadow effect */
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #top::after {
        content: "";
        position: absolute;
        left: -80%;
        right: -80%;
        bottom: -10px;
        border-bottom: 10px solid purple;
    }

    #texts,
    #services {
        width: auto;
    }

    #demonstration {
        margin-bottom: 50px;
    }

    .BtnLinks {
        margin-top: 20px;
        width: 300px;
    }

    #greeting,
    #demonstration,
    #payment,
    #Email {
        font-size: 20px;

    }

    #homeLogo {
        width: 100px;
        height: 108px;
    }

    * {
        margin: 0;
        padding: 0;
        margin: 0 auto;
    }

    ul {
        max-width: 320px;
        margin-left: 50px;
        font-size: 20px;
        text-align: center;
        height: 400px;
    }

    li {
        max-width: 320px;
        margin-left: 30px;
        font-size: 20px;
        text-align: center;
        height: 400px;
    }

    .BtnLinks {
        width: 150px;
        height: 70px;
    }

    #footer {
        height: 25vh;
        font-size: 20px;
    }

    #Email {
        text-align: center;
        font-size: 20px;
    }
}

/* Tablets (portrait and landscape) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {

    #GenerealPhoto,
    #texts,
    #services {
        width: auto;
    }

    #top,
    #greeting,
    #demonstration,
    #payment,
    #Email {
        font-size: 25px;
    }

    #homeLogo {
        width: 150px;
        height: 162px;
    }

    ul,
    li {
        width: auto;
        margin-left: 10%;
    }

    .BtnLinks {
        width: 175px;
        height: 75px;
    }

    #footer {
        height: 35vh;
        font-size: 25px;

    }
}

@media (max-width: 1024px) {
    #top {
        margin-bottom: 35px;
        font-size: 35px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        /* Shadow effect */
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #top::after {
        content: "";
        position: absolute;
        left: -35%;
        right: -35%;
        bottom: -10px;
        border-bottom: 8px solid purple;


    }
}

@media screen and (max-width: 375px) {
    ul {
        display: flex;
        width: auto;
        height: 400px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 10px;
        margin-left: 25px;


    }


    li {
        margin-top: 35px;
        list-style-type: square;
        font-weight: normal;
        width: auto;
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: normal;
        font-style: normal;
    }
}
@media screen and (width:540px) and (height: 720px) {
    ul {
        display: flex;
        width: 1050px;
        height: 400px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 10px;
        margin-left: 15%;

    }
    
    
    li {
        width: 400px;
        margin-top: 35px;
        margin-left: 20%;
        list-style-type: square;
        font-weight: normal;
    
    }
    
}