* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
}


.sidebar {
  width: 230px;
  position: fixed;
  top: 0;
  left: -250px;
  height: 100vh;
  background: #F7F7F7;
  transition: left 0.3s;
  z-index: 1000;
  overflow-y: hidden;
}

.show {
  left: 0;

}

.hide {
  left: -250px;
}

.sidebar a {
  padding: 5px;
  text-decoration: none;
  font-size: 15px;
  color: black;
  display: block;
  transition: color 0.3s;
  margin-left: 20px;
  margin-top: 20px;
}

.sidebar a:hover {
  color: gray;
}


#sidebarLogo {
  margin-left: 20px;
  margin-top: 70px;
  max-height: 56px;
  max-width: 225px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


#menu {
  cursor: pointer;
  width: 45px;
  height: 20px;
  color: white;
  position: absolute;
  top: 10px;
  left: 10px;
}

#closeBTN {
  border: none;
  width: 50px;
  height: 25px;
  cursor: pointer;
  background-color: #F7F7F7;
  color: black;
  position: absolute;
  top: 10px;
  right: 10px;
}


#NOOR {
  padding: 15px;
  text-decoration: none;
  font-size: 20px;
  color: purple;
  display: block;
  transition: color 0.3s;
  margin-left: 10px;
  margin-top: 40px;
}

