* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
}

aside {
    width: auto;
    margin: 0 auto;
    overflow-x: hidden;
}

#contactPhoto {
    width: auto;
    background-image: url('../pictures/support.jpg');
    height: 450px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 35px;
    text-decoration: underline purple 10px;
    color: white;
}
#Contact {
    margin-bottom: 35px;
    font-size: 45px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow effect */
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#Contact::after {
    content: "";
    position: absolute;
    left: -35%; /* Adjust as needed */
    right: -35%; /* Adjust as needed */
    bottom: -10px; /* Adjust as needed */
    border-bottom: 10px solid purple; 
}


#quote {
    text-align: center;
    font-size: 30px;
    margin-top: 150px;
}

p {
    text-align: center;
}

#phone,
#email {
    color: #5F0197;
    margin-top: 100px;
    font-size: 25px;
}

#MailLink,
#phoneLink {
    color: #5F0197;
}

#Touch {
    margin-top: 50px;
    color: gray;
    font-weight: lighter;
}

#link {
    color: gray;
    font-weight: normal;
}

@media screen and (max-width: 600px) {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: bold;
        font-style: normal;
    }

    #contactPhoto {
        width: auto;
        height: 400px;
        background-attachment: scroll;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(http://www.b3-cs.com/wp-content/uploads/2015/03/sapsupport1.jpg);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 25px;
        text-decoration: underline purple;
        object-fit: cover;
        object-position: center;
        color: white;
        background-size: cover;
    }

    #quote {
        font-size: 20px;
    }

    #phone {
        font-size: 20px;
    }

    #email {
        font-size: 20px;
    }

    #Touch {
        margin-top: 110px;
    }
    #Contact {
        margin-bottom: 35px;
        font-size: 24px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow effect */
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #Contact::after {
        content: "";
        position: absolute;
        left: -55%; 
        right: -55%; 
        bottom: -10px; 
        border-bottom: 10px solid purple;  
    }
}
@media (max-width: 1024px) {
    #Contact {
        margin-bottom: 35px;
        font-size: 35px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow effect */
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #Contact::after {
        content: "";
        position: absolute;
        left: -45%;
        right: -45%; 
        bottom: -10px; 
        border-bottom: 8px solid purple; 
    
    
    }
}