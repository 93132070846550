* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
}

.TermsOfServicePhoto {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(https://truckinginsurance-tx.com/wp-content/uploads/2023/12/Commercial-Truck-Insurance-Texas-1536x698.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 35px;
    text-decoration: underline purple 10px;
    color: white;
    height: 400px;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
}

#terms {
    margin-bottom: 35px;
    font-size: 45px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow effect */
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#terms::after {
    content: "";
    position: absolute;
    left: -35%; /* Adjust as needed */
    right: -35%; /* Adjust as needed */
    bottom: -10px; /* Adjust as needed */
    border-bottom: 10px solid purple; 
}

#policy-text {
    width: 950px;
    margin-left: 275px;
    margin-top: 50px;
    transition: 1s;
}

#policy-text-clos {
    width: 950px;
    transition: 1s;
    margin-left: 275px;
    margin-top: 50px;
    margin: 0 auto;
}

.TermsOfServiceText {
    text-transform: inherit;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.6;
    text-align: justify;
}

.TermsOfServiceOnlyText {
    text-transform: inherit;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.6;
    text-align: justify;
    margin: 25px 0;
}

.TermsOfServiceTopics {
    font-size: 25px;
    font-weight: bold;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-style: normal;
    margin-top: 20px;
    display: flex;
    justify-content: start;
}

.TermsOfServiceFinishText {
    text-transform: inherit;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.6;
}
/* For tablets */
@media (max-width: 1024px) {
    #policy-text, #policy-text-clos {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    #topics{
        margin-top: 15px;
    }
    #terms {
        margin-bottom: 35px;
        font-size: 35px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow effect */
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #terms::after {
        content: "";
        position: absolute;
        left: -35%;
        right: -35%; 
        bottom: -10px; 
        border-bottom: 8px solid purple; 
    }

    
}

/* For mobile phones */
@media (max-width: 480px) {
    #policy-text, #policy-text-clos {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .TermsOfServiceTopics {
        font-size: 20px;
        text-align: center;
    }

    .TermsOfServiceText, .TermsOfServiceOnlyText, .TermsOfServiceFinishText {
        font-size: 10px;
        margin-top: 10px;
        font-size: 20px;
        min-width: 320px;
        margin-left: 10px;
    }
    #topics{
        margin-top: 15px;
        font-size: 30px;
        text-align: left;
        margin-left: 10px;
        width: auto;
    }
    #TermsOfServicePhoto{
        width: auto;
        height: 450px;
        background-size: cover cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(https://truckinginsurance-tx.com/wp-content/uploads/2023/12/Commercial-Truck-Insurance-Texas-1536x698.jpg);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 25px;
    
    }
    #terms {
        margin-bottom: 35px;
        font-size: 25px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow effect */
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #terms::after {
        content: "";
        position: absolute;
        left: -35%;
        right: -35%; 
        bottom: -10px; 
        border-bottom: 8px solid purple; 
    }
}