* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
}

section {
    width: auto;
    margin: 0 auto;
    overflow-x: hidden;
}
#Policy {
    margin-bottom: 35px;
    font-size: 45px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow effect */
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#Policy::after {
    content: "";
    position: absolute;
    left: -35%; /* Adjust as needed */
    right: -35%; /* Adjust as needed */
    bottom: -10px; /* Adjust as needed */
    border-bottom: 10px solid purple; 
}

#privacyPphoto {
    width: auto;
    height: 450px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(https://truckinginsurance-tx.com/wp-content/uploads/2023/12/Commercial-Truck-Insurance-Texas-1536x698.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 35px;
    color: white;
}

#policy-text {
    width: auto;
    height: auto;
}
#policy-text-clos{
    width: auto;
    height: auto;
    transition: 1s;
    margin: 0 auto;
}
.topics {
    font-size: 25px;
    font-weight: bold;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-style: normal;
    margin-top: 20px;
    display: flex;
    justify-content: start;
}

.text1 {
    margin-top: 25px;
    text-transform: inherit;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.6;
    text-align: justify;
}

#info {
    margin-top: 20px;
}

#profile {
    margin-top: 20px;
}

#serv {
    margin-top: 20px;
}

section {
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
}

#Policy {
    text-align: center;
    font-size: 45px;
    color: white;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
    margin: 20px 0;
}

#privacyPphoto {
    width: auto;
    background-image: url(https://truckinginsurance-tx.com/wp-content/uploads/2023/12/Commercial-Truck-Insurance-Texas-1536x698.jpg);
    height: 450px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 35px;
    text-decoration: underline purple 10px;
    color: white;
}

#policy-text, 
#policy-text-clos {
    width: auto;
    height: auto;
    margin: 50px auto;
}

.topics {
    font-size: 25px;
    font-weight: bold;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-style: normal;
    margin-top: 20px;
    display: flex;
    justify-content: start;
}

.text1 {
    margin-top: 25px;
    text-transform: inherit;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.6;
    text-align: justify;
}

#info, 
#profile, 
#serv {
    margin-top: 20px;
}
@media only screen and (max-width: 600px) {
    .topics{
        font-size: 30px;
        text-align: left;
        margin-left: 10px;
        width: auto;
        max-width: 320px;

    }
    .text1{
        font-size: 20px;
        max-width: auto;
        margin-left: 10px;
    }
    #privacyPphoto{
        width: auto;
        height: 450px;
        background-size: cover cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(https://truckinginsurance-tx.com/wp-content/uploads/2023/12/Commercial-Truck-Insurance-Texas-1536x698.jpg);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 25px;
        text-decoration: underline purple;
        object-fit: cover;
        object-position: center;
        color: white;
    }
    #Policy {
        margin-bottom: 35px;
        font-size: 25px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow effect */
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #Policy::after {
        content: "";
        position: absolute;
        left: -35%; 
        right: -35%; 
        bottom: -10px; 
        border-bottom: 10px solid purple;  
    }
}

@media screen and (max-width: 1024px) {
    #Policy {
        margin-bottom: 35px;
        font-size: 35px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow effect */
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #Policy::after {
        content: "";
        position: absolute;
        left: -35%;
        right: -35%; 
        bottom: -10px; 
        border-bottom: 8px solid purple; 
    }
} 